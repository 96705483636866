import { render, staticRenderFns } from "./WorkCard.vue?vue&type=template&id=ef1d86f0&"
import script from "./WorkCard.vue?vue&type=script&lang=js&"
export * from "./WorkCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QSeparator,QRating,QTooltip,QLinearProgress,QIcon,QChip});
