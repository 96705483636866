//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotifyMixin from '../../mixins/Notification.js'

export default {
  name: 'Scanner',

  mixins: [NotifyMixin],

  data () {
    return {
      tab: 'tasks',
      state: null, // ['running', 'finished', 'error']
      loggedIn: false,
      tasks: [], // 正在处理中的并行任务
      failedTasks: [], // 处理失败的任务
      mainLogs: [],
      results: []
    }
  },

  sockets: {
    SCAN_TASKS (payload) {
      this.tasks = payload.tasks
    },
    SCAN_FAILED_TASKS (payload) {
      this.failedTasks = payload.failedTasks
    },
    SCAN_MAIN_LOGS (payload) {
      this.mainLogs = payload.mainLogs
    },
    SCAN_RESULTS (payload) {
      this.results = payload.results
    },
    SCAN_INIT_STATE (payload) {
      this.state = 'running'
      this.tasks = payload.tasks
      this.failedTasks = payload.failedTasks
      this.mainLogs = payload.mainLogs
      this.results = payload.results
    },

    SCAN_FINISHED (payload) {
      this.state = 'finished'
      this.allLogs.push({
        level: 'info',
        message: payload.message
      })
    },
    SCAN_ERROR () {
      this.state = 'error'
    },

    success () {
      this.loggedIn = true
    }
  },

  methods: {
    performScan () {
      this.tasks = []
      this.failedTasks = []
      this.mainLogs = []
      this.results = []
      this.state = 'running'
      this.$socket.emit('PERFORM_SCAN')
    },

    performUpdate () {
      this.tasks = []
      this.failedTasks = []
      this.mainLogs = []
      this.results = []
      this.state = 'running'
      this.$socket.emit('PERFORM_UPDATE')
    },

    killScanProceess () {
      this.$socket.emit('KILL_SCAN_PROCESS')
    },
  },

  computed: {
    allLogs () {
      const resultLogs = this.results.map(res => {
        if (res.result === 'added') {
          return { level: 'info', message: `[RJ${res.rjcode}] 添加成功! Added: ${res.count}` }
        } else if (res.result === 'updated') {
          return { level: 'info', message: `[RJ${res.rjcode}] 更新成功! Updated: ${res.count}` }
        } else {
          return { level: 'error', message: `[RJ${res.rjcode}] 处理失败! Failed: ${res.count}` }
        }
      })
      return this.mainLogs.concat(resultLogs)
    }
  },

  mounted () {
    this.$socket.emit('ON_SCANNER_PAGE')
    this.$socket.on('connect_error', () => {
      this.showErrNotif('连接Socket失败')
    });
  },
}
