import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=6e49b01d&"
import script from "./MainLayout.vue?vue&type=script&lang=js&"
export * from "./MainLayout.vue?vue&type=script&lang=js&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QPageScroller from 'quasar/src/components/page-scroller/QPageScroller.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QInput,QIcon,QDrawer,QScrollArea,QList,QItem,QItemSection,QItemLabel,QDialog,QCard,QCardSection,QAvatar,QCardActions,QPageContainer,QPage,QPageScroller,QFooter});qInstall(component, 'directives', {Ripple,ClosePopup});
