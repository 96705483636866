import { render, staticRenderFns } from "./WorkDetails.vue?vue&type=template&id=407559af&"
import script from "./WorkDetails.vue?vue&type=script&lang=js&"
export * from "./WorkDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QRating from 'quasar/src/components/rating/QRating.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QRating,QTooltip,QLinearProgress,QIcon,QChip,QBtnDropdown,QList,QItem,QItemSection,QAvatar,QItemLabel,QBtn});
