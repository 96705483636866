import { render, staticRenderFns } from "./PlayerBar.vue?vue&type=template&id=c4758ef8&"
import script from "./PlayerBar.vue?vue&type=script&lang=js&"
export * from "./PlayerBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSlideTransition,QItem,QItemSection,QImg,QItemLabel,QBtn});qInstall(component, 'directives', {Ripple});
