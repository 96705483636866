//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import WorkDetails from 'components/WorkDetails'
// import CoverSFW from 'components/CoverSFW'

export default {
  name: 'WorkListItem',

  props: {
    metadata: {
      type: Object,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    samCoverUrl () {
      // 从 LocalStorage 中读取 token
      const token = this.$q.localStorage.getItem('jwt-token') || ''
      return this.metadata.id ? `/api/cover/${this.metadata.id}?type=sam&token=${token}` : ""
    },
  }
}
