var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h5 text-weight-regular q-ma-md"},[_vm._v("\n    "+_vm._s(_vm.pageTitle)+"\n    "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagination.totalCount),expression:"pagination.totalCount"}]},[_vm._v("\n      ("+_vm._s(_vm.pagination.totalCount)+")\n    ")])]),_c('div',{class:("row justify-center " + (_vm.listMode ? 'list' : 'q-mx-md'))},[_c('q-infinite-scroll',{staticClass:"col",staticStyle:{"max-width":"1680px"},attrs:{"offset":250,"disable":_vm.stopLoad},on:{"load":_vm.onLoad},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"row justify-center q-my-md"},[_c('q-spinner-dots',{attrs:{"color":"primary","size":"40px"}})],1)]},proxy:true}])},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.works.length),expression:"works.length"}],staticClass:"row justify-between q-mb-md q-mr-sm"},[_c('q-select',{staticClass:"col-auto",attrs:{"dense":"","rounded":"","outlined":"","bg-color":"white","transition-show":"scale","transition-hide":"scale","options":_vm.options,"label":"排序"},model:{value:(_vm.sortOption),callback:function ($$v) {_vm.sortOption=$$v},expression:"sortOption"}}),_c('q-btn-toggle',{staticClass:"col-auto",staticStyle:{"width":"85px"},attrs:{"dense":"","spread":"","rounded":"","toggle-color":"primary","color":"white","text-color":"primary","options":[
            { icon: 'apps', value: false },
            { icon: 'list', value: true }
          ]},model:{value:(_vm.listMode),callback:function ($$v) {_vm.listMode=$$v},expression:"listMode"}}),(_vm.$q.screen.width > 700 && _vm.listMode)?_c('q-btn-toggle',{staticClass:"col-auto",staticStyle:{"width":"85px"},attrs:{"dense":"","spread":"","rounded":"","toggle-color":"primary","color":"white","text-color":"primary","options":[
            { icon: 'label', value: true },
            { icon: 'label_off', value: false }
          ]},model:{value:(_vm.showLabel),callback:function ($$v) {_vm.showLabel=$$v},expression:"showLabel"}}):_vm._e(),(_vm.$q.screen.width > 700 && !_vm.listMode)?_c('q-btn-toggle',{staticClass:"col-auto",staticStyle:{"width":"85px"},attrs:{"dense":"","spread":"","rounded":"","disable":_vm.$q.screen.width < 1120,"toggle-color":"primary","color":"white","text-color":"primary","options":[
            { icon: 'zoom_in', value: true },
            { icon: 'zoom_out', value: false } ]},model:{value:(_vm.detailMode),callback:function ($$v) {_vm.detailMode=$$v},expression:"detailMode"}}):_vm._e()],1),(_vm.listMode)?_c('q-list',{staticClass:"shadow-2",attrs:{"bordered":"","separator":""}},_vm._l((_vm.works),function(work){return _c('WorkListItem',{key:work.id,attrs:{"metadata":work,"showLabel":_vm.showLabel && _vm.$q.screen.width > 700}})}),1):_c('div',{staticClass:"row q-col-gutter-x-md q-col-gutter-y-lg"},_vm._l((_vm.works),function(work){return _c('div',{key:work.id,staticClass:"col-xs-12 col-sm-6 col-md-4",class:_vm.detailMode ? 'col-lg-3 col-xl-3': 'col-lg-2 col-xl-2'},[_c('WorkCard',{staticClass:"fit",attrs:{"metadata":work,"thumbnailMode":!_vm.detailMode}})],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stopLoad),expression:"stopLoad"}],staticClass:"q-mt-lg q-mb-xl text-h6 text-bold text-center"},[_vm._v("END")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }